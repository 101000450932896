import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import SubNavbar from '../components/SubNavbar'
import Layout from '../components/Layout'
import './styles/blueprint-features-page.scss'

export const BlueprintFeaturesPageTemplate = ({
  helmet,
  hero,
  one_tool,
  case_study,
  how_it_works,
  features_section,
  seo_analysis,
  internal_linking,
  content_strategy,
}) => (
  <div className="blueprint-features-pg">
    {helmet || ''}

    <section className="hero-section">
      <div className="container">
        <div className="hero-section-txt">
          <div className="section-preheading">{hero.preheading}</div>
          <h1 className="section-heading">{hero.heading}</h1>
          <p>{hero.subheading}</p>

          <div className="cta-button">
            <a className="btn cta-btn" href="https://blueprintseo.humanautomation.ai/signup" rel="noopener noreferrer">
              {hero.cta_label}
            </a>
          </div>
        </div>

        <picture className="hero-section-img">
          <source srcset={hero.image.mobile.childImageSharp.fluid.src} media="(max-width: 768px)" />
          <img src={hero.image.desktop.childImageSharp.fluid.src} alt={hero.heading} />
        </picture>
      </div>
    </section>

    <section className="one-tool-section">
      <div className="one-tool-section-header">
        <h2 className="section-heading">{one_tool.heading}</h2>
        <p>{one_tool.subheading}</p>
      </div>

      <picture>
        <source srcset={one_tool.image.mobile.childImageSharp.fluid.src} media="(max-width: 768px)" />
        <img src={one_tool.image.desktop.childImageSharp.fluid.src} alt={one_tool.heading} loading="lazy" />
      </picture>

      <div className="one-tool-platforms">
        <p>{one_tool.platforms.heading}</p>
        <div className="one-tool-platforms-list">
          {one_tool.platforms.items.map(({ image }, key) => (
            <figure key={key} class={(image.relativePath.indexOf('shopify') >= 0) ? 'coming-soon' : null}>
              <PreviewCompatibleImage imageInfo={image} />
            </figure>
          ))}
        </div>
      </div>
    </section>

    <section className="case-study-section">
      <div className="case-study-section-inner">
        <figure>
          <PreviewCompatibleImage imageInfo={case_study.image} />
        </figure>

        <div className="case-study-txt">
          <div className="section-preheading">Case Study:</div>
          <h2 className="section-heading">{case_study.heading}</h2>
          <div className="case-study-link">
            <a href="https://human.marketing/work/new-horizons-case-study/">{case_study.link_label}</a>
          </div>
        </div>
      </div>
    </section>

    <section className="how-it-works-section">
      <div className="hiw-inner">
        <h2 className="section-heading">{how_it_works.heading}</h2>

        <ol className="hiw-steps">
          {how_it_works.steps.map((step, key) => (
            <li className="hiw-steps-item" key={key}>
              <div className="hiw-steps-txt">
                <h3 className="section-heading">{step.heading}</h3>
                <p>{step.subheading}</p>
              </div>
              <figure>
                <PreviewCompatibleImage imageInfo={step.image} />
              </figure>
            </li>
          ))}
        </ol>
      </div>
    </section>

    <section className="features-section bg-dark-blue">
      <div className="container">
        <div className="features-section-header">
          <h2 className="section-heading">{features_section.heading}</h2>
          <p>{features_section.subheading}</p>
        </div>

        <div className="features-section-grid">
          <figure class="framed-image">
            <PreviewCompatibleImage imageInfo={seo_analysis.image} />
          </figure>
          <div className="features-section-txt">
            <div className="section-preheading">{seo_analysis.preheading}</div>
            <h3 className="section-heading">{seo_analysis.heading}</h3>
            <div dangerouslySetInnerHTML={{ __html: seo_analysis.subheading }} />

            <ul className="features-section-list">
              {seo_analysis.items.map((item, key) => (
                <li className="features-section-list-item" key={key}>
                  <figure>
                    <PreviewCompatibleImage imageInfo={item.image} />
                  </figure>
                  <div>{item.text}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="features-section bg-light-blue">
      <div className="container">
        <div className="features-section-grid grid-txt-img">
          <figure className="internal-linking-img">
            <PreviewCompatibleImage imageInfo={internal_linking.image} />
          </figure>
          <div className="features-section-txt">
            <div className="section-preheading">{internal_linking.preheading}</div>
            <h3 className="section-heading">{internal_linking.heading}</h3>
            <div dangerouslySetInnerHTML={{ __html: internal_linking.subheading }} />
          </div>
        </div>
      </div>
    </section>

    <section className="features-section bg-dark-blue">
      <div className="container">
        <div className="features-section-grid">
          <figure class="framed-image">
            <PreviewCompatibleImage imageInfo={content_strategy.image} />
          </figure>
          <div className="features-section-txt">
            <div className="section-preheading">{content_strategy.preheading}</div>
            <h3 className="section-heading">{content_strategy.heading}</h3>
            <div dangerouslySetInnerHTML={{ __html: content_strategy.subheading }} />
          </div>
        </div>
      </div>
    </section>
  </div>
)

const BlueprintFeaturesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <div className="subnavtop">
      <SubNavbar software={frontmatter.software} />
      <Layout software={frontmatter.software} footerProps={{ waves: false }}>
        <BlueprintFeaturesPageTemplate
          {...frontmatter}
          helmet={
            <Helmet>
              <title>{frontmatter.metatitle}</title>
              <meta name="description" content={frontmatter.metadescription} />
            </Helmet>
          }
        />
      </Layout>
    </div>
  )
}

export default BlueprintFeaturesPage

export const pageQuery = graphql`
query BlueprintFeaturesPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "blueprint-features-page"}}) {
    frontmatter {
      software
      templateKey
      title
      metatitle
      metadescription
      hero {
        preheading
        heading
        subheading
        cta_label
        image {
          desktop {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                src
              }
            }
          }
          mobile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                src
              }
            }
          }
        }
      }
      one_tool {
        heading
        subheading
        image {
          desktop {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                src
              }
            }
          }
          mobile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                src
              }
            }
          }
        }
        platforms {
          heading
          items {
            image {
              relativePath
              childImageSharp {
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      case_study {
        heading
        link_label
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      how_it_works {
        heading
        steps {
          heading
          subheading
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      features_section {
        heading
        subheading
      }
      seo_analysis {
        preheading
        heading
        subheading
        items {
          text
          image {
            childImageSharp {
              fluid(maxWidth: 100, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      internal_linking {
        preheading
        heading
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      content_strategy {
        preheading
        heading
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
}
`
